/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Biu0dPvx6",
    "aws_user_pools_web_client_id": "7u3h401u9ett3ers014i7j62pk",
    "oauth": {
        "domain": "nflpickgames.auth.us-east-1.amazoncognito.com"
    }
};


export default awsmobile;

import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { Link } from "react-router-dom";
import { Navigation } from '../../containers';
import { getGamesPerWeek } from '../../services';
import { formatDateTime } from '../../utils';

const setUserFromCognito = async (setUser) => {
  setUser(await Auth.currentAuthenticatedUser());
}

const ChangeGameTimesLandingPage = () => {
  const [gamesByWeek, setGamesByWeek] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUserFromCognito(setUser);
    const loadAllData = async () => {
      loadGamesData();
    }
    loadAllData();
  }, []);

  const loadGamesData = async () => {
    const gamesData = await getGamesPerWeek();
    if (gamesData) {
      setGamesByWeek(gamesData);
    };
  }

  return (
    <div>
      <Navigation pageName='Change Game Times'>
        {SummaryTable(gamesByWeek, user)}
      </Navigation>
    </div>
  );
}

export default ChangeGameTimesLandingPage;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#3f51b5',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 0,
  },
});

const SummaryTable = (gamesByWeek, user) => {
  const classes = useStyles();

  if (gamesByWeek.length > 0) {
    return (
      <>
        {user !== null && user.attributes.email === 'connort@gmail.com' ? (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align='center'><Typography>Week</Typography></StyledTableCell>
                  <StyledTableCell align='center'><Typography>Winners Set</Typography></StyledTableCell>
                  <StyledTableCell align='center'><Typography>First Game</Typography></StyledTableCell>
                  <StyledTableCell align='center'><Typography>Last Game</Typography></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gamesByWeek.map((row) => (
                  <StyledTableRow key={row.weekName}>
                    <StyledTableCell align='center'>
                      <Link to={`/change-game-times/${row.weekNumber}`}>
                        <Typography>
                          Week {row.weekName}
                        </Typography>
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align='center'><Typography>{row.numberOfWinners} of {row.numberOfGames}</Typography></StyledTableCell>
                    <StyledTableCell align='center'>
                      <Typography>
                        {formatDateTime(row.minTime)}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <Typography>
                        {formatDateTime(row.maxTime)}
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h6" align="center">You don't have access to this page</Typography>
        )}
      </>
    );
  } else {
    return (
      <CircularProgress />
    );
  }
}
import axios from 'axios';
import { Auth } from 'aws-amplify';
import apiConfig from '../config';

const getStandingsByWeek = async (weekNumber) => {
    console.log('-- Getting Picks for One Week --');
    const user = await Auth.currentAuthenticatedUser();
    const jwtToken = user.signInUserSession.accessToken.jwtToken;
    const config = {
        headers: {
            'Content-Type': 'application/json',
            jwtheader: jwtToken,
        },
        crossDomain: true
    };	

    const url = `${apiConfig.baseUrl}/picks/${weekNumber}`;
    console.log('-- Getting from URL --', url);
    try {
        const response = await axios.get(url, config);
        const data = response.data;
        if(data.success) {
            // Since it's only for 1 user, only return 1st item in picks array (will only be 1 item)
            return {
                games: data.picks.games,
                picks: data.picks.picks.length > 0 ? data.picks.picks[0].picks : [],
            };
        } else {
            return {
                games: undefined,
                picks: undefined
            };
        }
    } catch (error) {
        console.log('-- API Error --', error);
        return {
            games: undefined,
            picks: undefined
        };
    }
};

export default getStandingsByWeek;

import axios from 'axios';
import { Auth } from 'aws-amplify';
import apiConfig from '../config';

const putGameTime = async (gameId, weekNumber, weekName, gameDateTime, mondayNightFlag) => {
    console.log('-- Putting a Game Date/Time --');
    const user = await Auth.currentAuthenticatedUser();
    const jwtToken = user.signInUserSession.accessToken.jwtToken;
    const config = {
        headers: {
            'Content-Type': 'application/json',
            jwtheader: jwtToken,
        },
        crossDomain: true
    };

    const url = `${apiConfig.baseUrl}/games/update/${gameId}`;
    const gameData = {
        weekNumber,
        weekName,
        guessPointsFlag: mondayNightFlag,
        mondayNightFlag,
        gameDateTime,
    };
    // console.log('-- Putting Game Object --', gameData);
    // console.log('-- Putting to URL --', url);
    try {
        const response = await axios.put(url, gameData, config);
        const data = response.data;
        if(data.success) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log('-- API Error --', error);
        return false;
    }
};

export default putGameTime;

import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { Navigation } from '../../containers';
import { getOnePlayerPicksByWeek, putPick } from '../../services';
import { formatTime, playoffWeekNames } from '../../utils';

const SetPicksPage = () => {
  const [games, setGames] = useState([]);
  const [picks, setPicks] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [pageName, setPageName] = useState('Set Picks');
  const { weekNumber } = useParams();

  useEffect(() => {
    if (weekNumber !== undefined) {
      const loadPicksData = async (weekNumber) => {
        const pickData = await getOnePlayerPicksByWeek(weekNumber);
        setDataLoading(false);
        if (pickData.games !== undefined) {
          const rawGames = pickData.games;
          rawGames.sort((a, b) => {
            return a.gameDateTime - b.gameDateTime;
          });
          setGames(rawGames);
        }
        if (pickData.picks !== undefined) {
          setPicks(pickData.picks);
        }
      }

      loadPicksData(weekNumber);
      const name = playoffWeekNames.find(week => week.weekNumber === parseInt(weekNumber));
      setPageName(name === undefined ? `Set Picks for Week ${weekNumber}` : `Set Picks for ${name.weekName}`); 
    }
  }, [weekNumber]);

  const setPick = (gameId, pickedTeamId, totalPoints) => {
    // console.log('-- Set Pick --', gameId, pickedTeamId, totalPoints);
    const newPicks = picks.filter(pick => pick.gameId !== gameId);
    newPicks.push({ gameId, pickedTeamId, totalPoints});
    setPicks(newPicks);
    putPick(gameId, pickedTeamId, totalPoints);
  }

  return (
    <div>
      <Navigation pageName={`${pageName}`}>
        {dataLoading && (
          <CircularProgress />
        )}
        {PicksTable(games, picks, setPick)}
      </Navigation>
    </div>
  );
}

export default SetPicksPage;


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#3f51b5',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 0,
  },
});

const PicksTable = (games, picks, setPick) => {
  const classes = useStyles();
  if (games.length > 0) {
    const gamesWithTime = [];
    for(let i = 0; i < games.length; i++) {
      const game = games[i];
      const timeString = formatTime(game.gameDateTime);
      gamesWithTime.push({ ...game, timeString });
    };
    return (
      <>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableBody>
              {renderAllRows(gamesWithTime, picks, setPick)}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  } else {
    return null;
  };
}

const renderAllRows = (games, picks, setPick) => {
  let lastTime = '';
  let result = [];
  for(let i = 0; i < games.length; i++) {
    if(games[i].timeString !== lastTime) {
      result.push (
        <>
          <StyledTableRow key={games[i].timeString}>
            <StyledTableCell colSpan={2} style={{backgroundColor: '#3f51b5'}}>
              <Typography style={{fontWeight: 'bold', color: 'white'}}>
                {games[i].timeString}
              </Typography>
            </StyledTableCell>
          </StyledTableRow>
          {RenderPickRow(games[i], picks, setPick)}
        </>
      );
    } else {
      result.push (
        <>
          {RenderPickRow(games[i], picks, setPick)}
        </>
      );
    }
    lastTime = games[i].timeString;
  }
  return result;
}

const RenderPickRow = (game, picks, setPick) => {
  const pick = picks.find((pick) => pick.gameId === game.gameId);
  const disabledPick = game.gameDateTime <= Date.now();

  const buttonClickHandler = (teamId) => {
    if(!disabledPick) {
      if(pick === undefined || pick.pickedTeamId !== teamId) {
        setPick(game.gameId, teamId, pick === undefined ? 0 : pick.totalPoints);
      }
    }
  }

  const textChangeHandler = (event) => {
    if(!disabledPick) {
      setPick(game.gameId, pick.pickedTeamId, event.target.value > 0 ? event.target.value : 0);
    }
  };

  const visitingTeamColor = pick !== undefined && pick.pickedTeamId === game.visitingTeam.teamId ? 'primary' : 'default';
  const homeTeamColor = pick !== undefined && pick.pickedTeamId === game.homeTeam.teamId ? 'primary' : 'default';

  return (
    <StyledTableRow key={game.gameId}>
      <StyledTableCell align='center'>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          {pick !== undefined && game.winningTeamId !== null && game.winningTeamId !== 0 && game.winningTeamId !== undefined && game.winningTeamId === pick.pickedTeamId && (
            <DoneOutlineIcon style={{ color: '#51B53F', paddingRight: 5 }} key={`${game.gameId}-Icon`} />
          )}
          {game.winningTeamId !== null && game.winningTeamId !== 0 && game.winningTeamId !== undefined && (pick === undefined || game.winningTeamId !== pick.pickedTeamId) && (
            <CloseIcon style={{ paddingRight: 5 }} color='secondary' key={`${game.gameId}-Icon`} />
          )}
          <Button variant='contained' disableElevation={disabledPick} onClick={() => buttonClickHandler(game.visitingTeam.teamId)} color={visitingTeamColor} key={`${game.gameId}-visitingTeam`}>
            <Avatar alt={game.visitingTeam.teamName} src={game.visitingTeam.imageUrl} />
          </Button>
          <Typography style={{ paddingLeft: 5, paddingRight: 5 }} key={`${game.gameId}-at`}>@</Typography>
          <Button variant='contained' disableElevation={disabledPick} onClick={() => buttonClickHandler(game.homeTeam.teamId)} color={homeTeamColor} key={`${game.gameId}-homeTeam`}>
            <Avatar alt={game.homeTeam.teamName} src={game.homeTeam.imageUrl} />
          </Button>
          {game.guessPointsFlag && (
            <TextField
              id="outlined-number"
              type="number"
              variant='outlined'
              placeholder='Points (Total)'
              style={{paddingLeft: 10, width: 140}}
              value={pick !== undefined ? pick.totalPoints : ''}
              onChange={textChangeHandler}
              disabled={disabledPick}
              key={`${game.gameId}-points`}
            />
          )}
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
}
import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Navigation } from '../../containers';
import { getAllParticipantStatus, putParticipant } from '../../services';

const setUserFromCognito = async (setUser) => {
  setUser(await Auth.currentAuthenticatedUser());
}

const ManageParticipantsPage = () => {
  const [participants, setParticipants] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUserFromCognito(setUser);
    const loadParticipants = async () => {
      const participantsData = await getAllParticipantStatus();
      setDataLoading(false);
      if (participantsData !== undefined) {
        participantsData.sort((a, b) => {
          const fa = a.username.toLowerCase(), fb = b.username.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        setParticipants(participantsData);
      }
    }

    loadParticipants();
  }, []);

  const updateParticipant = (userId, email, username, playingSeason, playingPlayoffs, paid) => {
    // console.log('-- Update Participant --', userId, email, username, playingSeason, playingPlayoffs, paid);
    const newParticipants = participants.filter(participant => participant.userId !== userId);
    newParticipants.push({ userId, email, username, playingSeason, playingPlayoffs, paid });
    newParticipants.sort((a, b) => {
      const fa = a.username.toLowerCase(), fb = b.username.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setParticipants(newParticipants);
    putParticipant(userId, playingSeason, playingPlayoffs, paid);
  }

  return (
    <div>
      <Navigation pageName='Manage Participants'>
        {dataLoading && (
          <CircularProgress />
        )}
        {ParticipantsTable(participants, updateParticipant, user)}
      </Navigation>
    </div>
  );
}

export default ManageParticipantsPage;


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#3f51b5',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 0,
  },
});

const ParticipantsTable = (participants, updateParticipant, user) => {
  const classes = useStyles();
  if (participants.length > 0) {
    return (
      <>
        {user !== null && user.attributes.email === 'connort@gmail.com' ? (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableBody>
                {renderAllRows(participants, updateParticipant)}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h6" align="center">You don't have access to this page</Typography>
        )}
      </>
    );
  } else {
    return null;
  };
}

const renderAllRows = (participants, updateParticipant) => {
  let result = [];
  for (let i = 0; i < participants.length; i++) {
    result.push(
      <>
        {RenderRow(participants[i], updateParticipant)}
      </>
    );
  }
  return result;
}

const RenderRow = (participant, updateParticipant) => {
  // const pick = picks.find((pick) => pick.gameId === game.gameId);
  // const disabledPick = game.gameDateTime <= Date.now();

  const buttonClickHandler = (playingSeason, playingPlayoffs, paid) => {
    const { userId, email, username } = participant;
    updateParticipant(userId, email, username, playingSeason, playingPlayoffs, paid);
  }

  return (
    <StyledTableRow key={participant.userId}>
      <StyledTableCell align='center'>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <Typography>
            {participant.username}
          </Typography>
          <Typography>
            {participant.email}
          </Typography>
        </div>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <Button variant='contained' onClick={() => buttonClickHandler(!participant.playingSeason, participant.playingPlayoffs, participant.paid)} color={participant.playingSeason ? 'primary' : 'default'} key={`${participant.userId}-Season`}>
          Regular Season
        </Button>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <Button variant='contained' onClick={() => buttonClickHandler(participant.playingSeason, !participant.playingPlayoffs, participant.paid)} color={participant.playingPlayoffs ? 'primary' : 'default'} key={`${participant.userId}-Playoffs`}>
          Playoffs
        </Button>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <Button variant='contained' onClick={() => buttonClickHandler(participant.playingSeason, participant.playingPlayoffs, !participant.paid)} color={participant.paid ? 'primary' : 'default'} key={`${participant.userId}-Paid`}>
          Paid
        </Button>
      </StyledTableCell>
    </StyledTableRow>
  );
}
const formatDateTime = (dt) => {
    const time = new Date(dt);
    const dayOfMonth = time.getDate();
    const monthIndex = time.getMonth();
    const monthName = months[monthIndex];
    let hour = time.getHours();
    const ampm = hour < 12 ? 'am' : 'pm';
    if (hour > 12) {
        hour -= 12;
    };
    let minutes = time.getMinutes();
    if (minutes < 10) {
        minutes = '0' + minutes;
    };
    return `${monthName} ${dayOfMonth}, ${hour}:${minutes}${ampm}`;
}

export default formatDateTime;

const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Navigation } from '../../containers';
import { getOnePlayerPicksByWeek, putGameTime } from '../../services';
import { formatTime, playoffWeekNames } from '../../utils';

const setUserFromCognito = async (setUser) => {
  setUser(await Auth.currentAuthenticatedUser());
}

const ChangeGameTimesPage = () => {
  const [games, setGames] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [pageName, setPageName] = useState('Change Game Times');
  const { weekNumber } = useParams();

  useEffect(() => {
    setUserFromCognito(setUser);
    if (weekNumber !== undefined) {
      const loadGamesData = async (weekNumber) => {
        const gameData = await getOnePlayerPicksByWeek(weekNumber);
        setDataLoading(false);
        if (gameData.games !== undefined) {
          const rawGames = gameData.games;
          rawGames.sort((a, b) => {
            if (a.gameDateTime < b.gameDateTime) {
              return -1;
            } else if (a.gameDateTime > b.gameDateTime) {
              return 1;
            } else {
              if (a.visitingTeam.teamId < b.visitingTeam.teamId) {
                return -1;
              } else if (a.visitingTeam.teamId > b.visitingTeam.teamId) {
                return 1;
              } else {
                return 0;
              }
            }
          });
          setGames(rawGames);
          // console.log('-- Games --', games);
        }
      }

      loadGamesData(weekNumber);
      const name = playoffWeekNames.find(week => week.weekNumber === parseInt(weekNumber));
      setPageName(name === undefined ? `Change Game Times for Week ${weekNumber}` : `Change Game Times for ${name.weekName}`); 
    }
  }, [weekNumber]);

  const changeTime = (gameId, weekNumber, weekName, newTime, mondayNightFlag) => {
    // console.log('-- Set Winner --', gameId, winningTeamId, totalPoints);
    const newGames = games.filter(game => game.gameId !== gameId);
    const newGame = games.find(game => game.gameId === gameId);
    newGame.gameDateTime = newTime;
    newGames.push(newGame);
    newGames.sort((a, b) => {
      if (a.gameDateTime < b.gameDateTime) {
        return -1;
      } else if (a.gameDateTime > b.gameDateTime) {
        return 1;
      } else {
        if (a.visitingTeam.teamId < b.visitingTeam.teamId) {
          return -1;
        } else if (a.visitingTeam.teamId > b.visitingTeam.teamId) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    setGames(newGames);
    putGameTime(gameId, weekNumber, weekName, newTime, mondayNightFlag)
    // putGameWinner(gameId, winningTeamId, totalPoints);
  }

  return (
    <div>
      <Navigation pageName={`${pageName}`}>
        {dataLoading && (
          <CircularProgress />
        )}
        {GamesTable(games, changeTime, user)}
      </Navigation>
    </div>
  );
}

export default ChangeGameTimesPage;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#3f51b5',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 0,
  },
});

const GamesTable = (games, changeTime, user) => {
  const classes = useStyles();
  if (games.length > 0) {
    const gamesWithTime = [];
    for (let i = 0; i < games.length; i++) {
      const game = games[i];
      const timeString = formatTime(game.gameDateTime);
      gamesWithTime.push({ ...game, timeString });
    };
    return (
      <>
        {user !== null && (user.attributes.email === 'gregwappett@gmail.com' || user.attributes.email === 'camorris13@gmail.com' || user.attributes.email === 'connort@gmail.com') ? (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableBody>
                {renderAllRows(gamesWithTime, changeTime)}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h6" align="center">You don't have access to this page</Typography>
        )}
      </>
    );
  } else {
    return null;
  };
}

const renderAllRows = (games, changeTime) => {
  let lastTime = '';
  let result = [];
  for (let i = 0; i < games.length; i++) {
    if (games[i].timeString !== lastTime) {
      result.push(
        <StyledTableRow key={games[i].timeString}>
          <StyledTableCell colSpan={2} style={{ backgroundColor: '#3f51b5' }}>
            <Typography style={{ fontWeight: 'bold', color: 'white' }}>
              {games[i].timeString}
            </Typography>
          </StyledTableCell>
        </StyledTableRow>
      );
    }
    result.push(RenderGameRow(games[i], changeTime));
    lastTime = games[i].timeString;
  }
  return result;
}

const RenderGameRow = (game, changeTime) => {
  const visitingTeamColor = game.winningTeamId === game.visitingTeam.teamId ? 'primary' : 'default';
  const homeTeamColor = game.winningTeamId === game.homeTeam.teamId ? 'primary' : 'default';

  const handleDateChange = (event) => {
    // console.log('-- Game --', game);
    const { weekNumber, weekName } = game;
    const newDate = new Date(event.target.value);
    const newGameDateTime = newDate.getTime();
    const mondayNightFlag = newDate.getDay() === 1 ? true : false;
    changeTime(game.gameId, weekNumber, weekName, newGameDateTime, mondayNightFlag);
    // console.log(weekNumber, weekName, newDate, newGameDateTime, mondayNightFlag);
  };

  const gameTime = new Date(new Date(game.gameDateTime).toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0];
  return (
    <StyledTableRow key={game.gameId}>
      <StyledTableCell align='center'>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Button variant='contained' disableElevation color={visitingTeamColor} key={`${game.gameId}-visitingTeam`}>
            <Avatar alt={game.visitingTeam.teamName} src={game.visitingTeam.imageUrl} />
          </Button>
          <Typography style={{ paddingLeft: 5, paddingRight: 5 }} key={`${game.gameId}-at`}>@</Typography>
          <Button variant='contained' disableElevation color={homeTeamColor} key={`${game.gameId}-homeTeam`}>
            <Avatar alt={game.homeTeam.teamName} src={game.homeTeam.imageUrl} />
          </Button>
        </div>
      </StyledTableCell>
      <StyledTableCell align='center'>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <TextField
            id="datetime-local"
            type="datetime-local"
            defaultValue={gameTime}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleDateChange}
          />
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
}
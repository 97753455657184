import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Navigation } from '../../containers';
import { getPayoutStructure } from '../../services';

const PayoutsPage = () => {
  const [payoutStructure, setPayoutStructure] = useState({});
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    const loadPayoutData = async () => {
      const payoutData = await getPayoutStructure();
      setDataLoading(false);
      setPayoutStructure(payoutData);
    }
    
    loadPayoutData();
  }, []);

  console.log('-- Payout Structure --', payoutStructure);
  return (
    <div>
      <Navigation pageName='Payouts'>
        {dataLoading && (
          <CircularProgress />
        )}
        {!dataLoading && (
          <Typography>The winner of each regular season week will get ${payoutStructure.weeklyPayout}.
          {payoutStructure.finalized ? (
            <>
              <Typography variant='h5' style={{paddingTop: 30}}>
                Regular Season:
              </Typography>
              {payoutStructure.regularSeason.map((rank, index) => (
                <Typography key={index}>
                  {rank.rank}: ${rank.payout}
                </Typography>
              ))}
              <Typography variant='h5' style={{paddingTop: 30}}>
                Playoffs:
              </Typography>
              {payoutStructure.playoffs.map((rank, index) => (
                <Typography key={index}>
                  {rank.rank}: ${rank.payout}
                </Typography>
              ))}
            </>
          ) : (
            <Typography>
              End-of-season and playoff payouts are still TBD (waiting for everyone to pay).
            </Typography> 
          )}
          </Typography>
        )}
      </Navigation>
    </div>
  );
}

export default PayoutsPage;

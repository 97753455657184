import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { Link } from "react-router-dom";
import { Navigation } from '../../containers';
import { getDashboard, getUserStatus } from '../../services';

const HomePage = () => {
  const [picksByWeek, setPicksByWeek] = useState([]);
  const [userStatus, setUserStatus] = useState(null);

  useEffect(() => {
    const loadAllData = async () => {
      loadDashboardData();
      loadUserStatus();
    }
    loadAllData();
  }, []);

  const loadDashboardData = async () => {
    const dashboardData = await getDashboard();
    if (dashboardData) {
      setPicksByWeek(dashboardData);
    };
  }

  const loadUserStatus = async () => {
    const userStatusData = await getUserStatus();
    if (userStatusData) {
      setUserStatus(userStatusData);
    };
  }

  return (
    <div>
      <Navigation pageName='Home'>
        {userStatus && !userStatus.playingSeason && (
          <Typography variant='h5'>
            It doesn't look like you're playing this season. If you think this is wrong, please reach out to Connor immediately.
          </Typography>
        )}
        {userStatus && userStatus.playingSeason && !userStatus.paid && (
          <Typography variant='h5'>
            You haven't paid yet, please do so right away
          </Typography>
        )}
        <Typography>
          Welcome to Pick 'Em! If you're having any troubles with the new site, please reach out to Connor immediately.
        </Typography>
        {SummaryTable(picksByWeek, userStatus)}
      </Navigation>
    </div>
  );
}

export default HomePage;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#3f51b5',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 0,
  },
});

const SummaryTable = (picksByWeek, userStatus) => {
  const classes = useStyles();
  const timestamp = new Date().getTime();
  // console.log("-- picksByWeek --", picksByWeek);

  if (userStatus && picksByWeek) {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align='center'><Typography>Week</Typography></StyledTableCell>
              <StyledTableCell align='center'><Typography># Picked</Typography></StyledTableCell>
              <StyledTableCell align='center'><Typography>Standings</Typography></StyledTableCell>
              <StyledTableCell align='center'><Typography>Picks</Typography></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {picksByWeek.map((row) => (
              <StyledTableRow key={row.weekName}>
                {row.pickingWeek && userStatus.playingSeason ? (
                  <StyledTableCell align='center'>
                    <Link to={`/pick/${row.weekNumber}`}>
                      <Typography>
                        {row.weekName} - {timestamp > row.maxTime ? 'See Your Picks' : 'Make Your Picks'}
                      </Typography>
                    </Link>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align='center'>
                    <Typography>
                      {row.weekName}
                    </Typography>
                  </StyledTableCell>
                )}
                <StyledTableCell align='center'><Typography>{row.numberOfPicks} of {row.numberOfGames}</Typography></StyledTableCell>
                <StyledTableCell align='center'>
                  <Link to={`/standings/${row.weekNumber}`}>
                    <Typography>
                      Standings
                    </Typography>
                  </Link>
                </StyledTableCell>
                <StyledTableCell align='center'>
                  <Link to={`/view-picks/${row.weekNumber}`}>
                    <Typography>
                      All Picks
                    </Typography>
                  </Link>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <CircularProgress />
    );
  }
}
import Amplify from 'aws-amplify';
import React, {useState} from 'react';
import aws_exports from './aws-exports';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { 
  HomePage,
  ManageParticipantsPage,
  PayoutsPage,
  RulesPage,
  SetOthersPicksPage,
  SetPicksPage,
  SetWinnersLandingPage,
  SetWinnersPage,
  StandingsPage,
  StandingsOneWeekPage,
  ViewAllPicksPage,
  ChangeGameTimesLandingPage,
  ChangeGameTimesPage
} from './pages';
Amplify.configure(aws_exports);

const App = () => {
  const [authState, setAuthState] = useState('');

  const handleAuthStateChange = (state) => {
    if (state === 'signedin' || state === 'signedout') {
        setAuthState(state);
    }
  }
  
  return (
    <AmplifyAuthenticator handleAuthStateChange={handleAuthStateChange}>
      { authState !== 'signedin' ? (
        <AmplifySignIn slot='sign-in' hideSignUp={true} headerText={'Sign In to NFL Pick \'Em!'}>
          <div slot="federated-buttons"></div>
        </AmplifySignIn>
      ) : (
        <Router>
          <Switch>
            <Route exact path="/manage-participants">
              <ManageParticipantsPage />
            </Route>
            <Route exact path="/payouts">
              <PayoutsPage />
            </Route>
            <Route exact path="/rules">
              <RulesPage />
            </Route>
            <Route exact path="/pick/:weekNumber">
              <SetPicksPage />
            </Route>
            <Route exact path="/pick/:userId/:weekNumber">
              <SetOthersPicksPage />
            </Route>
            <Route exact path="/standings">
              <StandingsPage />
            </Route>
            <Route exact path="/standings/:weekNumber">
              <StandingsOneWeekPage />
            </Route>
            <Route exact path="/view-picks/:weekNumber">
              <ViewAllPicksPage />
            </Route>
            <Route exact path="/winners/:weekNumber">
              <SetWinnersPage />
            </Route>
            <Route exact path="/winners">
              <SetWinnersLandingPage />
            </Route>
            <Route exact path="/change-game-times/:weekNumber">
              <ChangeGameTimesPage />
            </Route>
            <Route exact path="/change-game-times">
              <ChangeGameTimesLandingPage />
            </Route>
            <Route exact path="/">
              <HomePage />
            </Route>
          </Switch>
        </Router>
      )}
    </AmplifyAuthenticator>
  );
}

export default App;

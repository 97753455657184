import axios from 'axios';
import { Auth } from 'aws-amplify';
import apiConfig from '../config';

const getPayoutStructure = async () => {
    console.log('-- Getting Payout Structure Data --');
    const user = await Auth.currentAuthenticatedUser();
    const jwtToken = user.signInUserSession.accessToken.jwtToken;
    const config = {
        headers: {
            'Content-Type': 'application/json',
            jwtheader: jwtToken,
        },
        crossDomain: true
    };	

    const url = `${apiConfig.baseUrl}/rules/payout-structure`;
    console.log('-- Getting from URL --', url);
    try {
        const response = await axios.get(url, config);
        const data = response.data;
        if(data.success) {
            return data.result;
        } else {
            return undefined;
        }
    } catch (error) {
        console.log('-- API Error --', error);
        return undefined;
    }
};

export default getPayoutStructure;

import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { Navigation } from '../../containers';
import { getStandings } from '../../services';

const StandingsPage = () => {
  const [standingsRegularSeason, setStandingsRegularSeason] = useState([]);
  const [standingsPlayoffs, setStandingsPlayoffs] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    const loadStandingsData = async () => {
      const standingsData = await getStandings();
      setDataLoading(false);
      // console.log('-- standingsData --', standingsData);
      if (standingsData.standingsInfo && standingsData.standingsInfo.regularSeason) {
        // console.log('-- Have Regular Season --');
        setStandingsRegularSeason(standingsData.regularSeason);
      }
      if (standingsData.standingsInfo && standingsData.standingsInfo.playoffs) {
        // console.log('-- Have Playoffs --');
        setStandingsPlayoffs(standingsData.playoffs);
      }
    }
    
    loadStandingsData();
  }, []);

  return (
    <div>
      <Navigation pageName='Standings'>
        {dataLoading && (
          <CircularProgress />
        )}
        {PlayoffStandings(standingsPlayoffs, 'Playoff Standings')}
        {RegularSeasonStandings(standingsRegularSeason, 'Regular Season Standings')}
        {!dataLoading && standingsPlayoffs.length === 0 && standingsRegularSeason.length === 0 && (
          <Typography variant='h6'>
            No Standings yet for this season
          </Typography>
        )}
      </Navigation>
    </div>
  );
}

export default StandingsPage;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#3f51b5',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 0,
  },
});

const RegularSeasonStandings = (standings, label) => {
  const classes = useStyles();
  if (standings.length > 0) {

    return (
      <>
        <Typography variant='h6'>
          {label}
        </Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align='center'><Typography>Rank</Typography></StyledTableCell>
                <StyledTableCell align='center'><Typography>User</Typography></StyledTableCell>
                <StyledTableCell align='center'><Typography>Record</Typography></StyledTableCell>
                <StyledTableCell align='center'><Typography>MNF</Typography></StyledTableCell>
                <StyledTableCell align='center'><Typography>Division</Typography></StyledTableCell>
                <StyledTableCell align='center'><Typography>Point Diff</Typography></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {standings.map((row) => (
                <StyledTableRow key={row.userId}>
                  <StyledTableCell align='center'><Typography>{row.rank}</Typography></StyledTableCell>
                  <StyledTableCell align='center'><Typography>{row.username}</Typography></StyledTableCell>
                  <StyledTableCell align='center'><Typography>{row.correct}-{row.incorrect}</Typography></StyledTableCell>
                  <StyledTableCell align='center'><Typography>{row.mnfCorrect}</Typography></StyledTableCell>
                  <StyledTableCell align='center'><Typography>{row.divisionCorrect}</Typography></StyledTableCell>
                  <StyledTableCell align='center'><Typography>{row.pointDifference}</Typography></StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  } else {
    return null;
  };
}

const PlayoffStandings = (standings, label) => {
  const classes = useStyles();
  if (standings.length > 0) {
    return (
      <div style={{paddingBottom: 40}}>
        <Typography variant='h6'>
          {label}
        </Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align='center'><Typography>Rank</Typography></StyledTableCell>
                <StyledTableCell align='center'><Typography>User</Typography></StyledTableCell>
                <StyledTableCell align='center'><Typography>Record</Typography></StyledTableCell>
                <StyledTableCell align='center'><Typography>Superbowl</Typography></StyledTableCell>
                <StyledTableCell align='center'><Typography>Conf Championship</Typography></StyledTableCell>
                <StyledTableCell align='center'><Typography>Divisional</Typography></StyledTableCell>
                <StyledTableCell align='center'><Typography>Wild Card</Typography></StyledTableCell>
                <StyledTableCell align='center'><Typography>Point Diff</Typography></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {standings.map((row) => (
                <StyledTableRow key={row.userId}>
                  <StyledTableCell align='center'><Typography>{row.rank}</Typography></StyledTableCell>
                  <StyledTableCell align='center'><Typography>{row.username}</Typography></StyledTableCell>
                  <StyledTableCell align='center'><Typography>{row.correct}-{row.incorrect}</Typography></StyledTableCell>
                  <StyledTableCell align='center'><Typography>{row.superbowlCorrect}</Typography></StyledTableCell>
                  <StyledTableCell align='center'><Typography>{row.conferenceChampionshipCorrect}</Typography></StyledTableCell>
                  <StyledTableCell align='center'><Typography>{row.divisionalCorrect}</Typography></StyledTableCell>
                  <StyledTableCell align='center'><Typography>{row.wildCardCorrect}</Typography></StyledTableCell>
                  <StyledTableCell align='center'><Typography>{row.pointDifference}</Typography></StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else {
    return null;
  };
}
const formatTime = (dt) => {
    const time = new Date(dt);
    const dayIndex = time.getDay();
    const dayName = days[dayIndex];
    let hour = time.getHours();
    const ampm = hour < 12 ? 'am' : 'pm';
    if (hour > 12) {
    hour -= 12;
    };
    let minutes = time.getMinutes();
    if (minutes < 10) {
    minutes = '0' + minutes;
    };
    return `${dayName} ${hour}:${minutes}${ampm}`;
}

export default formatTime;


const days = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ];
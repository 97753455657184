import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Navigation } from '../../containers';

const RulesPage = () => {
  return (
    <div>
      <Navigation pageName='Rules'>
        <Typography variant='h5'>The top 12 teams at the end of the regular season will continue to pick the Playoffs.</Typography>
        <Typography>Note: if two people tie for a weekly win, the weekly payout will go to whoever does better the following week.</Typography>
        <Typography variant='h5' style={{paddingTop: 30}}>Regular Season Standings (weekly winner and "who makes the playoffs") are determined by:</Typography>
        <Typography>1) Number of Correct Picks</Typography>
        <Typography>2) Correctly Picking the Monday Night Football Game</Typography>
        <Typography>3) Number of Correct Picks of Divisional Games</Typography>
        <Typography>4) Difference Between Points-Picked and Actual Points Scored in Monday Night Games (net-difference, NOT the price is right -- if 40 points are scored, 43 and 37 are both 3 off)</Typography>
        <Typography variant='h5' style={{paddingTop: 30}}>Playoff Standings are determined by:</Typography>
        <Typography>1) Number of Correct Picks</Typography>
        <Typography>2) Correctly Picking the Superbow.</Typography>
        <Typography>3) Number of Correct Picks in the Conference Championships</Typography>
        <Typography>4) Number of Correct Picks in the Divisional Round</Typography>
        <Typography>5) Number of Correct Picks in the Wild Card Round</Typography>
        <Typography>6) Difference Between Points-Picked and Actual Points Scored in All Games</Typography>
      </Navigation>
    </div>
  );
}

export default RulesPage;

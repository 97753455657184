import React from 'react';
import { useParams } from "react-router-dom";
import { Navigation } from '../../containers';

const SetOthersPicksPage = () => {
  let { weekNumber, userId } = useParams();
  return (
    <div>
      <Navigation pageName='Home'>
        <div>
          <p>
            Set Others Picks Page ({weekNumber}), for user ({userId})
          </p>
        </div>
      </Navigation>
    </div>
  );
}

export default SetOthersPicksPage;
